import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { checkLogin, getUser, strapi_end_point } from '../../utils';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const history = useHistory();
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.deviceSelectionStep);
  const [roomData, setRoomData] = useState([]);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  interface err {
    message: string;
  }
  const [mediaError, setMediaError] = useState<Error>();
  const GetUser = async (URLRoomName: any) => {
    try {
      var obj = {
        filters: {
          room: URLRoomName,
        },
        fields: ['id', 'room', 'room_sid'],
        populate: {
          user: {
            fields: ['id', 'username'],
          },
        },
        publicationState: 'live',
        locale: ['en'],
      };
      var query = qs.stringify(obj, {
        encodeValuesOnly: true, // prettify url
      });
      var res = await fetch(`${strapi_end_point}/api/groups?${query}`);
      var resDecode = await res.json();
      if (resDecode.meta.pagination.total > 0) {
        setRoomData(resDecode.data[0]);
      }
    } catch (errorEx) {
      var err = errorEx as err;
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      GetUser(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);
  useEffect(() => {
    let is_Login = checkLogin();
    if (!is_Login) {
      history.push('/');
    }
    let user = getUser();
    let userdata = JSON.parse(user);
    setName(userdata.username);
  }, []);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(null, '', window.encodeURI(`/group/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} roomData={roomData} />
      )}
    </IntroContainer>
  );
}
