import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import flipCamera from '../../images/flip-icon.svg';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import Snackbar from '../Snackbar/Snackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#000000',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    inputLinkContainer: {
      '& i': {
        color: '#ffffff',
        position: 'absolute',
        right: '10px',
        top: '20px',
        cursor: 'pointer',
      },
      position: 'relative',
      display: 'inline-block',
    },
    inputLink: {
      background: '#202020',
      border: 'none',
      padding: '18px 40px 18px 15px',
      display: 'inline-block',
      borderRadius: '10px',
      color: '#FFFFFF',
      outline: 'none',
      '&:placeholder': {
        color: '#FFFFFF',
      },
    },
    flipBtn: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuContainer: {},
    audioButtons: {
      display: 'flex',
      alignItems: 'center',
      '& button': {
        width: '60px',
        height: '60px',
        minWidth: '60px',
        background: '#202020',
        cursor: 'pointer',
        borderRadius: '50%',
        margin: '0 5px',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
          width: '40px',
          height: '40px',
          minWidth: '40px',
        },
        '& .text-audio': {
          display: 'none',
        },
        '& svg': {
          display: 'none',
        },
        '& .hideFirst': {
          display: 'block !important',
          lineHeight: '100%',
          marginTop: '5px',
          [theme.breakpoints.down('sm')]: {
            marginTop: '2px',
          },
        },
        '& .text-video': {
          display: 'none',
        },
        '& span': {
          margin: '0',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar(props: { roomData?: any }) {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const participants = useParticipants();
  const [success, setSuccess] = useState('');
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  return (
    <>
      <Snackbar
        open={success.length > 0 ? true : false}
        handleClose={() => setSuccess('')}
        headline={''}
        message={success}
        variant="info"
      />
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <div className={classes.inputLinkContainer}>
                <input type="text" value={room?.name} className={classes.inputLink} />
                <i
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    setSuccess('Link copied!');
                  }}
                  className="fa-regular fa-clone"
                ></i>
              </div>

              {/* <Typography variant="body1">
                {room!.name} | 
                {participants.length + 1} participant{participants.length ? 's' : ''}
              </Typography> */}
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center" className="button-main">
              <div className={classes.audioButtons}>
                <ToggleAudioButton disabled={isReconnecting} />
                <ToggleVideoButton disabled={isReconnecting} />
                {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
                {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
                {flipCameraSupported && (
                  <button className={classes.flipBtn} disabled={flipCameraDisabled} onClick={toggleFacingMode}>
                    <img src={flipCamera} alt="" width={24} className="bottomIcon" />
                  </button>
                )}
              </div>

              <Hidden smDown>
                <Menu roomData={props.roomData} />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton roomData={props.roomData} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
