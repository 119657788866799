import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import EndMeeting from '../../../components/EndMeeting';
import { getUser } from '../../../utils';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      padding: '10px 30px',
      fontSize: '16px',
      color: 'white',
      '&:hover': {
        background: '#DD2A2A',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string; roomData?: any }) {
  const classes = useStyles();
  const [showPopup, setShowPopUp] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const { room } = useVideoContext();
  useEffect(() => {
    let user = getUser();
    let userdata = JSON.parse(user);
    if (userdata.id === props.roomData.attributes.user.data.id) {
      setIsOwner(true);
    }
  }, []);

  return (
    <>
      {showPopup && <EndMeeting setShowPopUp={setShowPopUp} />}
      <Button
        onClick={() => {
          if (isOwner) {
            setShowPopUp(true);
          } else {
            room!.disconnect();
            window.location.href = '';
          }
        }}
        className={clsx(classes.button, props.className)}
        data-cy-disconnect
      >
        Leave
      </Button>
    </>
  );
}
