import { useState } from 'react';
import Header from '../components/header';
import { checkLogin, getUser, strapi_end_point, BACKEND_URL } from '../utils';
import Snackbar from '../components/Snackbar/Snackbar';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, CircularProgress } from '@material-ui/core';

import qs from 'qs';
export default function Home() {
  const history = useHistory();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [group_link, setGroupLink] = useState('');
  const [isFetching, setFetching] = useState(false);
  interface err {
    message: string;
  }
  function makeid(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const JoinRoom = async () => {
    try {
      let is_Login = checkLogin();
      if (!is_Login) {
        setError('Please login to your account to continue!');
        return false;
      }
      if (group_link && group_link.length > 0) {
        const lastSegment = group_link.split('/').pop();
        var obj = {
          filters: {
            room: lastSegment,
          },
          fields: ['id', 'room', 'type', 'room_sid', 'channel_id'],
          publicationState: 'live',
          locale: ['en'],
        };
        var query = qs.stringify(obj, {
          encodeValuesOnly: true, // prettify url
        });
        var res = await fetch(`${strapi_end_point}/api/groups?${query}`);
        var resDecode = await res.json();
        if (resDecode.meta.pagination.total > 0) {
          if (resDecode.data[0].attributes.type === 'later') {
            let UpdateRules = await UpdateRoomLater(lastSegment, resDecode.data[0].id);
            if (!UpdateRules.status) {
              setError(UpdateRules.data);
              return false;
            }
          }
          const rawResponse = await fetch(BACKEND_URL.toString(), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ checkRoomStatus: true, room_uniqueCode: lastSegment }),
          });
          const content = await rawResponse.json();
          if (content.status) {
            history.push(`/group/${lastSegment}`);
          } else {
            setError('Group has Ended By Host!');
            return false;
          }
        } else {
          setError('Link is not valid!');
          return false;
        }
      } else {
        setError('Link is not valid!');
        return false;
      }
    } catch (errorEx) {
      var err = errorEx as err;
      setError(err.message);
    }
  };
  const UpdateRoomLater = async (lastSegment: any, eid: number) => {
    try {
      const rawResponse = await fetch(BACKEND_URL.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ UpdateRoomRules: true, roomCode: lastSegment, entryId: eid }),
      });
      const content = await rawResponse.json();
      return content;
    } catch (errorEx) {
      var err = errorEx as err;
      setError(err.message);
    }
  };
  const CreateRomm = async (type: string, room_code?: string) => {
    try {
      setFetching(true);
      let is_Login = checkLogin();
      if (!is_Login) {
        setError('Please login to your account to continue!');
        return false;
      }
      if (!room_code || room_code.length === 0) {
        room_code = makeid(10);
      }

      let user = getUser();
      let userdata = JSON.parse(user);

      const rawResponse = await fetch(BACKEND_URL.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ create_room: true, user_id: userdata.id, room_code: room_code, type: type }),
      });
      const content = await rawResponse.json();
      setFetching(false);
      if (content.status) {
        if (type === 'now') {
          history.push(`/group/${content.data}`);
        } else if (type === 'later') {
          setGroupLink(window.location.origin + `/group/${content.data}`);
          setShowModal(true);
        }
      } else {
        setError(content.data);
      }
    } catch (errorEx) {
      setFetching(false);
      var err = errorEx as err;
      setError(err.message);
    }
  };
  return (
    <>
      <Header />
      <Snackbar
        open={error.length > 0 ? true : false}
        handleClose={() => setError('')}
        headline={''}
        message={error}
        variant="error"
      />
      <Snackbar
        open={success.length > 0 ? true : false}
        handleClose={() => setSuccess('')}
        headline={''}
        message={success}
        variant="info"
      />
      {isFetching ? (
        <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
          <div>
            <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '16px', color: '#00000' }}>
              {`Please Wait...`}
            </Typography>
          </div>
        </Grid>
      ) : (
        <section className="hero-section">
          <div className="innerDiv mx-auto">
            <div className="hero-row">
              <div>
                <img src="hero.png" alt="" />
              </div>
              <div>
                <h1>
                  <span className="text-primary">Group</span>, <span className="text-primary">learn</span> and{' '}
                  <span className="text-primary">fun</span>. Now free for everyone.
                </h1>
                <p>
                  We re-engineered the service we built for secure business meetings, GuruCool Group, to make it free
                  and available for all.
                </p>
                <div className="mt-15p relative btn-group">
                  <button
                    type="button"
                    className="hero-btn bg-primary"
                    onClick={e => {
                      setToggleDropdown(!toggleDropdown);
                    }}
                  >
                    <span>
                      <i className="fas fa-plus"></i> Create Meeting
                    </span>
                  </button>
                  <ul className="text-left link-dropdown" style={{ display: toggleDropdown ? 'block' : 'none' }}>
                    <li onClick={() => CreateRomm('later')} className="cursor-pointer">
                      <i className="fas fa-link-horizontal"></i> For Later
                    </li>
                    <li onClick={() => CreateRomm('now')}>
                      <i className="fas fa-plus"></i> Instant Group
                    </li>
                    <li>
                      <i className="fas fa-plus"></i> Constant Group
                    </li>
                    <li>
                      <i className="far fa-circle-dot"></i> Go Live
                    </li>
                  </ul>
                  <button type="button" className="hero-btn bg-none" onClick={() => setShowJoinModal(true)}>
                    Join Meeting
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {showModal ? (
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-header">
              <h3>Here's the link to your meeting</h3>
              <span onClick={() => setShowModal(false)} className="cursor-pointer">
                <i className="fas fa-times"></i>
              </span>
            </div>
            <div className="modal-body">
              <p>Copy this link and send it to people that you want to meet with.</p>
              <div className="input-group">
                <input type="text" value={group_link} placeholder="Link" />
                <i
                  onClick={() => {
                    navigator.clipboard.writeText(group_link);
                    setSuccess('Link copied!');
                  }}
                  className="far fa-copy cursor-pointer"
                ></i>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showJoinModal ? (
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-header">
              <h3>Enter the joining link</h3>
              <span onClick={() => setShowJoinModal(false)} className="cursor-pointer">
                <i className="fas fa-times"></i>
              </span>
            </div>
            <div className="modal-body">
              <p>Please copy the joining link in the box below to join the Group</p>
              <div className="input-group">
                <input
                  type="text"
                  value={group_link}
                  onChange={e => {
                    setGroupLink(e.target.value);
                  }}
                  placeholder="Link or code"
                  onKeyPress={e => e.key === 'Enter' && JoinRoom()}
                />
                <span className="" onClick={JoinRoom}>
                  join
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
