import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import Snackbar from '../Snackbar/Snackbar';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'black',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  inputLinkContainer: {
    '& i': {
      color: '#ffffff',
      position: 'absolute',
      right: '8px',
      top: '9px',
      cursor: 'pointer',
      fontSize: '12px',
    },
    position: 'relative',
    display: 'inline-block',
  },
  inputLink: {
    background: '#202020',
    border: 'none',
    padding: '10px 30px 10px 5px',
    display: 'inline-block',
    borderRadius: '10px',
    color: '#FFFFFF',
    outline: 'none',
    fontSize: '10px',
    ':placeholder': {
      color: '#FFFFFF',
    },
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid #ffffff',
      padding: 0,
      margin: '0 1em',
      color: '#ffffff',
    },
  },
}));

export default function MobileTopMenuBar({ roomData }: { roomData: any }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [success, setSuccess] = useState('');
  return (
    <>
      <Snackbar
        open={success.length > 0 ? true : false}
        handleClose={() => setSuccess('')}
        headline={''}
        message={success}
        variant="info"
      />
      <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
        {/* <Typography variant="subtitle1" className="text-white">
        {room!.name}
      </Typography> */}
        <div className={classes.inputLinkContainer}>
          <input type="text" value={room?.name} className={classes.inputLink} />
          <i
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setSuccess('Link copied!');
            }}
            className="fa-regular fa-clone"
          ></i>
        </div>
        <div>
          <EndCallButton className={classes.endCallButton} roomData={roomData} />
          <Menu buttonClassName={classes.settingsButton} roomData={roomData} />
        </div>
      </Grid>
    </>
  );
}
