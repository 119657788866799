import React, { useEffect, useState } from 'react';
import { checkLogin, getUser, getUserData } from '../utils';

export default function Room() {
  const [isLogin, setLogin] = useState(false);
  const [isFetching, setFetching] = useState(true);
  const [user, setUser] = useState<any>([]);
  const getUserData1 = async () => {
    let user1 = getUser();
    let userdata = JSON.parse(user1);
    const userD = await getUserData(userdata.id);
    setUser(userD);
    setFetching(false);
  };
  useEffect(() => {
    const login = checkLogin();
    if (login) {
      setLogin(true);
      getUserData1();
    }
  }, []);
  const handleLoginWindow = () => {
    window.location.href = 'https://login.gurucool.xyz/?last_url=' + window.location.href;
  };
  const [toggleDropdown, setToggleDropdown] = useState(false);

  return (
    <>
      <header className="bg-black header-main">
        <div className="innerDiv mx-auto">
          <div className="inner-header">
            <a href="#">
              {' '}
              <img src="logo.png" width={160} alt="" />
            </a>
            {isLogin && !isFetching ? (
              <div className="dropdown">
                <a
                  onClick={e => {
                    setToggleDropdown(!toggleDropdown);
                  }}
                >
                  {' '}
                  <img
                    src={`${user.attributes.avatar.data ? user.attributes.avatar.data.attributes.url : 'profile.png'}`}
                    width={50}
                    height={50}
                    alt=""
                    className="user-profile"
                  />
                </a>
                <ul className="dropdown-menu" style={{ display: toggleDropdown ? 'block' : 'none' }}>
                  <li>
                    <a href="https://padhaai.gurucool.xyz">Padhaai</a>
                  </li>
                  <li>
                    <a href="/recording-management">Recording Management</a>
                  </li>
                  <li>
                    <a href="https://login.gurucool.xyz/logout">Logout </a>
                  </li>
                </ul>
              </div>
            ) : (
              <button className="login-btn" onClick={handleLoginWindow}>
                Login
              </button>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
