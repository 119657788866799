import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import userProfile from '../images/user-icon.png';
import { BACKEND_URL, strapi_end_point } from '../utils';

import useVideoContext from '../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrapper: {
      background: 'rgba(32, 32, 32, 0.2)',
      backdropFilter: 'blur(60px)',
      borderRadius: '20px',
      padding: '15px 30px',
      display: 'inline-block',
      position: 'fixed',
      left: '50%',
      transform: 'translate(-50%)',
      top: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      zIndex: 9,
      '& img': {
        borderRadius: '50%',
      },
    },
    ModalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& h4': {
        color: 'white',
      },
      '& i': {
        color: 'white',
      },
    },
    requestInnerContent: {
      paddingTop: '20px',
      '& p': {
        color: '#F6F6F6',
        fontWseight: 500,
        fontSize: '14px',
      },
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      paddingTop: '30px',

      '& button': {
        background: 'none',
        border: 'none',
      },
      '& span': {
        color: 'white',
        display: 'inline-block',
        fontSize: '14px',
        cursor: 'pointer',
      },
      '& span:first-child': {
        marginRight: '15px',
      },
    },
  })
);
interface RoomNameScreenProps {
  setShowPopUp: (roomName: boolean) => void;
}
export default function EndMeeting({ setShowPopUp }: RoomNameScreenProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  interface err {
    message: string;
  }
  const MarkasComplete = async () => {
    try {
      room!.disconnect();
      const rawResponse = await fetch(BACKEND_URL.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ complete_room: true, room_sid: room?.sid }),
      });
      await rawResponse.json();
      window.location.href = '';
    } catch (errorEx) {
      var err = errorEx as err;
      console.log(err.message);
    }
  };
  return (
    <>
      <div className={classes.mainWrapper}>
        <div className={classes.ModalHeader}>
          <h4>wants to join the meeting</h4>
          <i
            onClick={() => {
              setShowPopUp(false);
            }}
            className="fas fa-times"
          ></i>
        </div>
        <div className={classes.requestInnerContent}>
          <p>Ending the meeting for everyone also closes all breakout rooms.</p>
        </div>
        <div className={classes.modalFooter}>
          <button
            onClick={() => {
              room!.disconnect();
              window.location.href = '';
            }}
          >
            <span>Leave the meeting</span>
          </button>

          <button
            onClick={() => {
              MarkasComplete();
            }}
          >
            <span>End Meeting</span>
          </button>
        </div>
      </div>
    </>
  );
}
