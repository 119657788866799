import React from 'react';
// import AvatarIcon from '../../../../icons/AvatarIcon';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import userImage from '../../../../images/user.png';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: `${(9 / 16) * 100}%`,
    background: 'linear-gradient(180deg, #404040 0%, #111111 100%)',
    boxShadow: '0px 8px 16px -8px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${(9 / 10) * 100}%`,
    },
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  identityContainer: {
    position: 'absolute',
    bottom: '15px',
    zIndex: 1,
    left: '15px',
  },
  avtarContainer: {
    background: 'linear-gradient(150.07deg, #FFFFFF 14.35%, #C0BFBF 80.99%)',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      height: '70px',
    },
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.2)',
    color: 'white',
    padding: '5px 15px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    backdropFilter: 'blur(30px)',
  },
  avtarImage: {
    width: '48px',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
}));

export default function LocalVideoPreview({ identity }: { identity: string }) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classes.avatarContainer}>
            <div className={classes.avtarContainer}>
              <img src={userImage} alt="user" className={classes.avtarImage} />
            </div>
          </div>
        )}
      </div>

      <div className={classes.identityContainer}>
        <span className={classes.identity}>
          <LocalAudioLevelIndicator />
          <Typography variant="body1" color="inherit" component="span">
            {identity}
          </Typography>
        </span>
      </div>
    </div>
  );
}
