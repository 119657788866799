import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import userProfile from '../images/user-icon.png';
import { strapi_end_point } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrapper: {
      background: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(80px)',
      borderRadius: '20px',
      padding: '15px 20px',
      display: 'inline-block',
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        zIndex: '99',
      },
      top: '50px',
      '& img': {
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
          width: '35px',
          height: '35px',
        },
      },
    },
    requestInner: {
      display: 'flex',
    },
    requestInnerContent: {
      marginLeft: '15px',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '10px 15px',
      },
      '& h4': {
        color: '#ffffff',
        fontWeight: 500,
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
      '& p': {
        color: '#F6F6F6',
        fontSize: '12px',
      },
    },
    SuccessButton: {
      background: '#25AB32',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      color: 'white',
      border: 'none',
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '30px',
        fontSize: '14px',
      },
    },
    DangerButton: {
      background: '#C85353',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      color: 'white',
      border: 'none',
      fontSize: '20px',
      marginLeft: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '30px',
        marginLeft: '5px',
        fontSize: '14px',
      },
    },
  })
);

export default function JoinRequest({ request }: { request: any }) {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const manageRequest = async (opr: boolean, id: number) => {
    const data = {
      data: {
        is_denied: false,
        is_accept: false,
      },
    };
    if (opr) {
      data.data.is_accept = true;
    } else {
      data.data.is_denied = true;
    }
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    await fetch(`${strapi_end_point}/api/group-requests/${id}`, requestOptions);
    setShow(false);
  };
  return (
    <>
      {show && (
        <div className={classes.mainWrapper}>
          <div className={classes.requestInner}>
            <img
              src={
                request.attributes.user.data.attributes.profile_id.data.attributes.avatar.data != null
                  ? request.attributes.user.data.attributes.profile_id.data.attributes.avatar.data.attributes.url
                  : userProfile
              }
              width={50}
              height={50}
              alt=""
            />
            <div className={classes.requestInnerContent}>
              <h4>{request.attributes.user.data.attributes.username} wants to join the meeting</h4>
              <p>Click on accept to allow them</p>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  manageRequest(true, request.id);
                }}
                className={classes.SuccessButton}
              >
                <i className="fas fa-check"></i>
              </button>
              <button
                type="button"
                onClick={() => {
                  manageRequest(false, request.id);
                }}
                className={classes.DangerButton}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
