import React, { useState, useEffect } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import './App.css';
import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';
import JoinRequest from './components/JoinRequest';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import { getUser, strapi_end_point } from './utils';
const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [roomData, setRoomData] = useState([]);
  const [requests, setRequests] = useState([]);
  const [requestsFetching, setrequestsFetching] = useState(true);
  const [isLoading, setLoading] = useState(true);
  interface err {
    message: string;
  }
  const GetUser = async (roomname: any) => {
    try {
      var obj = {
        filters: {
          room: roomname,
        },
        fields: ['id', 'room', 'room_sid'],
        populate: {
          user: {
            fields: ['id', 'username'],
          },
        },
        publicationState: 'live',
        locale: ['en'],
      };
      var query = qs.stringify(obj, {
        encodeValuesOnly: true, // prettify url
      });
      var res = await fetch(`${strapi_end_point}/api/groups?${query}`);
      var resDecode = await res.json();
      if (resDecode.meta.pagination.total > 0) {
        setRoomData(resDecode.data[0]);
        setLoading(false);
      }
      let user = getUser();
      let userdata = JSON.parse(user);
      if (userdata.id === resDecode.data[0].attributes.user.data.id) {
        CheckRequest(resDecode.data[0].id);
      }
    } catch (errorEx) {
      var err = errorEx as err;
      console.log(err.message);
    }
  };
  const CheckRequest = async (id: number) => {
    try {
      setrequestsFetching(true);
      setInterval(async () => {
        var obj = {
          filters: {
            group: id,
            is_accept: false,
            is_denied: false,
          },
          fields: ['id', 'is_denied', 'is_accept'],
          populate: {
            user: {
              fields: ['id', 'username'],
              populate: {
                profile_id: {
                  fields: ['id'],
                  populate: {
                    avatar: '*',
                  },
                },
              },
            },
          },
          publicationState: 'live',
          locale: ['en'],
        };
        var query = qs.stringify(obj, {
          encodeValuesOnly: true, // prettify url
        });
        var res = await fetch(`${strapi_end_point}/api/group-requests?${query}`);
        var resDecode = await res.json();
        if (resDecode.meta.pagination.total > 0) {
          setRequests(resDecode.data);
          setrequestsFetching(false);
        }
      }, 5000);
    } catch (errorEx) {
      var err = errorEx as err;
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (URLRoomName && roomState === 'disconnected') {
      GetUser(URLRoomName);
    }
  }, [URLRoomName, roomState]);
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens />
      ) : (
        !isLoading && (
          <Main>
            <ReconnectingNotification />
            <RecordingNotifications />
            <MobileTopMenuBar roomData={roomData} />
            <Room />
            <MenuBar roomData={roomData} />
            {!requestsFetching &&
              requests.map((request: any) => {
                return <JoinRequest key={request.id} request={request} />;
              })}
          </Main>
        )
      )}
    </Container>
  );
}
