import React, { useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { checkLogin, getUser, strapi_end_point } from '../../../utils';
import qs from 'qs';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  joinButton: {
    width: '100%',
    background: '#F9C933',
    boxShadow: '0px 8px 16px -8px rgba(0, 0, 0, 0.25)',
    borderRadius: '11px',
    padding: '15px 20px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  marginTop: {
    marginTop: '1em',
  },
  rightButtonSection: {
    height: '100%',
  },
  deviceButton: {
    // width: '100%',
    border: '1px solid #DEDEDE;',
    margin: '1em 0',
    borderRadius: '11px',
    padding: '10px 20px',
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
    },
  },

  joinButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  roomData: any;
}

export default function DeviceSelectionScreen({ name, roomName, setStep, roomData }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const [isRequestion, setRequest] = useState(false);
  const [message, setMessage] = useState('Joining Group...');
  const [error, setError] = useState('');
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  interface err {
    message: string;
  }
  const JoinGroup = async () => {
    try {
      let user = getUser();
      let userdata = JSON.parse(user);
      if (userdata.id === roomData.attributes.user.data.id) {
        handleJoin();
      } else {
        setRequest(true);
        setMessage('Please wait while we asking to join!');
        var obj = {
          filters: {
            group: roomData.id,
            user: userdata.id,
          },
          fields: ['id', 'is_denied', 'is_accept'],
          publicationState: 'live',
          locale: ['en'],
        };
        var query = qs.stringify(obj, {
          encodeValuesOnly: true, // prettify url
        });
        var res = await fetch(`${strapi_end_point}/api/group-requests?${query}`);
        var resDecode = await res.json();
        if (resDecode.meta.pagination.total > 0) {
          if (resDecode.data[0].attributes.is_accept) {
            setRequest(false);
            setMessage('Joining Group...');
            handleJoin();
          } else if (resDecode.data[0].attributes.is_denied) {
            setRequest(false);
            setMessage('Joining Group...');
            setError('You are not allowed to join group!');
          } else {
            CheckIsLive(resDecode.data[0].id);
          }
        } else {
          const data1 = {
            data: {
              user: userdata.id,
              group: roomData.id,
              is_denied: false,
              is_accept: false,
            },
          };
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data1),
          };
          const JsonRes = await fetch(`${strapi_end_point}/api/group-requests`, requestOptions);
          const DataDecode = await JsonRes.json();
          CheckIsLive(DataDecode.data.id);
        }
      }
    } catch (errorEx) {
      var err = errorEx as err;
      setError(err.message);
    }
  };
  const CheckIsLive = (id: number) => {
    try {
      let TimeEvnt = setInterval(async () => {
        var obj = {
          fields: ['id', 'is_denied', 'is_accept'],
          publicationState: 'live',
          locale: ['en'],
        };
        var query = qs.stringify(obj, {
          encodeValuesOnly: true, // prettify url
        });
        var res = await fetch(`${strapi_end_point}/api/group-requests/${id}?${query}`);
        var resDecode = await res.json();
        if (resDecode.data) {
          if (resDecode.data.attributes.is_accept) {
            setRequest(false);
            setMessage('Joining Group...');
            handleJoin();
            clearTimeout(TimeEvnt);
          } else if (resDecode.data.attributes.is_denied) {
            setRequest(false);
            setMessage('Joining Group...');
            setError('You are not allowed to join group!');
            clearTimeout(TimeEvnt);
          }
        }
      }, 5000);
    } catch (errorEx) {
      var err = errorEx as err;
      setError(err.message);
    }
  };
  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      if (!token) {
        setError('this group has ended by host!');
        return false;
      }
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting || isRequestion) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '16px', color: '#00000' }}>
            {message}
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      {/* <Typography variant="h5" className={classes.gutterBottom}>
        Join {roomName}
      </Typography> */}
      <Snackbar
        open={error.length > 0 ? true : false}
        handleClose={() => setError('')}
        headline={''}
        message={error}
        variant="error"
      />
      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.rightButtonSection}
          >
            <div className={classes.joinButtons}>
              {/* <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
                Cancel
              </Button> */}
              <Button
                data-cy-join-now
                onClick={JoinGroup}
                disabled={disableButtons}
                className={classes.joinButton + ' primary-btn'}
              >
                Join Now
              </Button>
            </div>
            <div className={classes.joinButtons}>
              <Hidden>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
              {/* <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={7} sm={12} xs={12}>
        <div className={classes.mobileButtonBar}>
          {/* <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden> */}
          <SettingsMenu mobileButtonClass={classes.mobileButton} />
        </div>
      </Grid>
    </>
  );
}
