import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Table, Typography } from '@material-ui/core';
import Header from '../components/header';
import { BACKEND_URL, checkLogin, getUser } from '../utils';
import Snackbar from '../components/Snackbar/Snackbar';
import RecordingItem from '../components/RecordingItem';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableResponsive: {
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll',
      },
    },
    bgMain: {
      background: '#000000',
      height: '100%',
    },
    mainSection: {
      background: '#202020',
      borderRadius: '20px',
      padding: '50px 50px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 15px',
      },
      '& h2': {
        color: '#ffffff',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '36px',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px',
        },
      },
    },
    tableMain: {
      '& td': {
        padding: '.5rem .5rem',
        '& span': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
      '& thead td': {
        color: '#6F6F6F',
        fontWeight: 300,
      },
      '& tbody td': {
        color: '#FFFFFF',
        fontWeight: 400,
      },
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowX: 'hidden',
      // overflowY: 'scroll',
      position: 'fixed',
      inset: '0',
    },
    modalDialog: {
      position: 'relative',
      margin: '10px auto',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      '& video': {
        width: '100%',
        borderRadius: '10px',
      },
    },
    closeButton: {
      position: 'absolute',
      right: '0',
      top: '0',
      cursor: 'pointer',
      display: 'flex',
      width: '30px',
      height: '30px',
      background: '#ffffff',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 11,
      '& i': {
        fontSize: '20px',
        color: '#000000',
      },
    },
  })
);
export default function RecordingManagement() {
  const [isFetching, setFetching] = useState(true);
  const [data, setdata] = useState([]);
  const [error, setError] = useState('');
  const classes = useStyles();
  const GetData = async () => {
    let is_Login = checkLogin();
    if (!is_Login) {
      window.location.href = '/';
    }
    let user = getUser();
    let userdata = JSON.parse(user);
    const rawResponse = await fetch(BACKEND_URL.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ getRecordingsData: true, user_id: userdata.id }),
    });
    const content = await rawResponse.json();
    if (content.status) {
      setdata(content.data);
    } else {
      setError(content.data);
    }
    setFetching(false);
  };
  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <Snackbar
        open={error.length > 0 ? true : false}
        handleClose={() => setError('')}
        headline={''}
        message={error}
        variant="error"
      />
      <Header />
      <div className={classes.bgMain}>
        <div className="innerDiv mx-auto">
          <section className={classes.mainSection}>
            <h2>Recording Management</h2>
            {isFetching ? (
              <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
                <div>
                  <CircularProgress variant="indeterminate" />
                </div>
                <div>
                  <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '16px', color: '#fff' }}>
                    {`Please Wait...`}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <div className={classes.tableResponsive}>
                <Table className={classes.tableMain}>
                  <thead>
                    <tr>
                      <td>Sr. No</td>
                      <td>Type</td>
                      <td>Download</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((rec, index) => {
                        return <RecordingItem key={index} index={index + 1} recording={rec} classes={classes} />;
                      })
                    ) : (
                      <h3>No Recording Found!</h3>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
}
