import React, { useState } from 'react';

export default function RecordingItem({ index, recording, classes }: { index: any; recording: any; classes: any }) {
  const [showModal, setShowModal] = useState(false);
  var s = recording
    .split('.')
    .pop()
    .substring(
      0,
      recording
        .split('.')
        .pop()
        .indexOf('%')
    );
  return (
    <>
      {showModal ? (
        <>
          <div className={classes.modal}>
            <div className={classes.modalDialog}>
              {/*body*/}
              <div className="">
                <span className={classes.closeButton} onClick={() => setShowModal(false)}>
                  <i className="fas fa-times"></i>
                </span>
                <video>
                  <source src="sound.mp4" />
                </video>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <tr>
        <td>{index}</td>
        <td>{s === 'mkv' ? 'Video' : 'Audio'}</td>
        <td>
          <span className="text-primary">
            <a href={recording}>Download</a>
          </span>
        </td>
      </tr>
    </>
  );
}
